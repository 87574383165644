import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'cccisd-loader';
import moment from 'moment';
import { updateParams, getSavedParams } from 'js/reducers/params.js';
import style from './style.css';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

class ExportData extends React.Component {
    static propTypes = {
        params: PropTypes.object,
        getSavedParams: PropTypes.func,
    };

    state = {
        loading: true,
    };

    componentDidMount = async () => {
        if (!this.props.params.csvUpdated) {
            await this.props.getSavedParams(null, 'csv');
        }
        this.setState({ loading: false });
    };

    getLocalTime = () => {
        const { csvUpdated } = this.props.params;
        const updatedDateTime = moment
            .utc(csvUpdated)
            .tz('America/New_York')
            .format('MM/DD/YYYY HH:mm');

        return <span style={{ fontWeight: 'bold' }}>{updatedDateTime}</span>;
    };

    render() {
        const { csvUpdated } = this.props.params;
        if (this.state.loading) {
            return <Loader loading={this.state.loading} />;
        }
        const localTime = this.getLocalTime();
        return (
            <div className="container">
                <h1>Export Data</h1>

                <div className={style.block}>
                    <div className={style.blockLeft}>
                        <div className={style.mhqTriangleBackground} />
                    </div>

                    <div className={style.trsTriangleBackground} />
                </div>
                <h5>
                    <em>
                        {csvUpdated ? (
                            <>All CSV data last updated: {localTime}</>
                        ) : (
                            'CSV data is updated nightly'
                        )}
                    </em>
                </h5>
                <div className={style.row}>
                    <div className={style.column}>
                        <h4>Mental Health Profile</h4>
                        <div className={style.exportButtons}>
                            <a
                                href={Boilerplate.url(
                                    'api/resources/file/private/csvs/smhp-state.csv'
                                )}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                >
                                    State/Territory Aggregate
                                </button>
                            </a>
                            <a
                                href={Boilerplate.url(
                                    'api/resources/file/private/csvs/smhp-entity.csv'
                                )}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                >
                                    Entity Aggregate
                                </button>
                            </a>

                            <a
                                href={Boilerplate.url(
                                    'api/resources/file/private/csvs/smhp-district.csv'
                                )}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                >
                                    District Aggregate
                                </button>
                            </a>

                            <a
                                href={Boilerplate.url(
                                    'api/resources/file/private/csvs/smhp-school.csv'
                                )}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                >
                                    School Aggregate
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className={style.column}>
                        <h4>Assessment Data</h4>
                        <div className={style.exportButtons}>
                            <a
                                href={Boilerplate.url(
                                    'api/resources/file/private/csvs/smhqa-district.csv'
                                )}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                >
                                    SMH-QA District Raw
                                </button>
                            </a>

                            <a
                                href={Boilerplate.url(
                                    'api/resources/file/private/csvs/smhqa-school.csv'
                                )}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                >
                                    SMH-QA School Raw
                                </button>
                            </a>

                            <a
                                href={Boilerplate.url(
                                    'api/resources/file/private/csvs/smhqa-entity.csv'
                                )}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                >
                                    SMH-QA Entity Raw
                                </button>
                            </a>

                            <a
                                href={Boilerplate.url('api/resources/file/private/csvs/trs.csv')}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                >
                                    TRS Raw
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className={style.column}>
                        <h4>Registration</h4>
                        <div className={style.exportButtons}>
                            <a
                                href={Boilerplate.url(
                                    'api/resources/file/private/csvs/user-registration.csv'
                                )}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                >
                                    Registration Data
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className={style.column}>
                        <h4>TRS Follow-up</h4>
                        <div className={style.exportButtons}>
                            <a
                                href={Boilerplate.url(
                                    'api/resources/file/private/csvs/trs-follow-up.csv'
                                )}
                                rel="noopener noreferrer"
                            >
                                <button
                                    type="button"
                                    className={`btn btn-primary btn-md ${style.csvButton}`}
                                >
                                    TRS Follow-up
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    params: state.app.params,
});

export default connect(mapStateToProps, { updateParams, getSavedParams })(ExportData);
